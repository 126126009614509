<template>
  <div id="dialog-container" ref="dialogContainer">
    <el-dialog title="动态添加女朋友" :visible.sync="dialogVisible" width="40%">
      <div class="query-detail">
        <el-form ref="ruleForm" label-width="150px" :model="interForm">
          <div v-for="(item, index) in list" :key="index" :value="item">
            <el-form-item label="姓名 :">
              <div class="dynamic-box">
                <el-input v-model="item.name" />
                <i v-if="index===0" class="el-icon-circle-plus-outline" @click="addInputHandle()" />
                <i v-else class="el-icon-remove-outline" @click="delInputHandle(index)" />
              </div>
            </el-form-item>
          </div>
          <el-form-item>
            <el-button type="primary" @click="handleOk('ruleForm')">确定</el-button>
            <el-button @click="handleCancel('ruleForm')">返回</el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: "title"
    },
    visible: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      list: [{'name':''}]
    };
  },
  computed: {
    dialogVisible: {
      get: function() {
        return this.visible;
      },
      set: function(val) {
        this.$emit("update:visible", val);
      }
    }
  },
  created() {
    
  },
  methods: {
    handleConfrim(e) {
      e.preventDefault();
      this.$emit("beSure");
    },
    addInputHandle() {
      this.list.push({ name: "" });
      console.log(this.list)
    },
    delInputHandle(index) {
      this.list.splice(index, 1);
    },
    handleCancel() {
      this.dialogVisible = false;
    }
  }
};
</script>

<style scoped lang="scss">
.dynamic-box {
  display: flex;
  flex-direction: row;
  align-items: center;
  i {
    font-size: 24px;
    margin-left: 15px;
  }
}
</style>

